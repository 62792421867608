import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {SharedModule} from '../shared/shared.module';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {EnvironmentConfiguration} from '../shared/EnvironmentConfiguration';
import {IgoService} from '../services/igo.service';
import {MapService} from '../services/map.service';
import {LoaderService} from '../services/loader.service';
import {PredictService} from '../services/predict.service';
import {PlanComponent} from './plan/plan.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {Map3dComponent} from './plan/map-3d/map-3d.component';
import {Map2dComponent} from './plan/map-2d/map-2d.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EchelleComponent } from './plan/echelle/echelle.component';
import {SvgService} from '../services/svg.service';
import {MapperService} from '../services/mapper.service';
import {CreatePlanDialogComponent} from './dashboard/create-plan.dialog.component';
import {EnjeuxComponent} from './plan/enjeux/enjeux.component';
import { CcmComponent } from './plan/ccm/ccm.component';
import { StrategieCommunaleComponent } from './plan/strategie-communale/strategie-communale.component';
import {SocketService} from '../services/socket.service';
import {DownloadService} from '../services/download.service';
import { LoginComponent } from './login/login.component';

import { LegendeComponent } from './plan/legende/legende.component';
import { CartoucheComponent } from './plan/cartouche/cartouche.component';
import {TitleDialogComponent} from './plan/title/title.dialog.component';
import { UploadDialogComponent } from './plan/upload-dialog/upload.dialog.component';
import {AuthService} from '../services/auth.service';
import {LocalParamsService} from '../services/local-params.service';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {UnauthorizedComponent} from './unauthorized.component';
import {WrongDeviceComponent} from './wrong-device.component';
import {JwtLoginComponent} from './jwt-login.component';

@NgModule({ declarations: [
        AppComponent,
        PlanComponent,
        DashboardComponent,
        Map3dComponent,
        Map2dComponent,
        CreatePlanDialogComponent,
        EchelleComponent,
        EnjeuxComponent,
        CcmComponent,
        StrategieCommunaleComponent,
        LoginComponent,
        LegendeComponent,
        CartoucheComponent,
        TitleDialogComponent,
        UnauthorizedComponent,
        JwtLoginComponent,
        WrongDeviceComponent,
        UploadDialogComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        SharedModule,
        BrowserAnimationsModule], providers: [
        IgoService,
        MapService,
        LoaderService,
        PredictService,
        SvgService,
        MapperService,
        SocketService,
        DownloadService,
        AuthService,
        LocalParamsService,
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'fill' }
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (http: HttpClient) => () => EnvironmentConfiguration.load(http),
            multi: true,
            deps: [HttpClient]
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
