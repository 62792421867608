import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, startWith, switchMap} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';
import {MapperService} from '../../services/mapper.service';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-user-autocomplete',
  template: `
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Utilisateur</mat-label>
      <input type="text" [required]="required" matInput [formControl]="myControl" (keyup)="getData(myControl.value)"
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option (onSelectionChange)="user.emit(u)" *ngFor="let u of listUser$ | async" [value]="u">
          <b>{{ u.name_user }}</b> - {{ u.group.name_group }}
        </mat-option>
      </mat-autocomplete>
      <ng-content></ng-content>
    </mat-form-field>
  `,
})
export class UserAutocompleteComponent implements OnInit {

  @ViewChild('auto', {static: false}) auto;

  @Input() required: boolean;
  @Output() user = new EventEmitter();

  _user;
  myControl = new UntypedFormControl();
  _listUser: any[] = [];
  listUser$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  constructor(
    public mapperService: MapperService,
    public authService: AuthService,
  ) {
    this.authService.getSession().subscribe(user => {
      this._user = user;
    });
  }

  ngOnInit(): void {
    this.myControl.valueChanges
      .pipe(
        filter(a => !!a),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value: string) => {
          if (typeof value !== 'string') {
            return of(this._listUser);
          }
          // Get data according to the filled value
          return this.getData(value);
        })
      )
      .subscribe((data: any) => {
        data = data.filter((user) => user.id_group !== 11);
        this._listUser = data;
        this.listUser$.next(data);
      });
  }

  getData(term): any {
    const request = {
      filters: {
        is_enable: true,
        is_supervisor: false,
        is_supersupervisor: false,
      },
      search: term,
      rows: ['name_user'],
    };

    return this.mapperService.getUserWithFilters(10, 0, request);
  }

  displayFn(user): string {
    return user && user.name_user ? `${user.name_user} - ${user.group.name_group}`  : '';
  }
}

