<h2 mat-dialog-title>Nouvelle carte A0</h2>
<mat-dialog-content>
  <form #form="ngForm" [hidden]="!user">
      <div class="mb-3" *ngIf="user?.is_admin">
        <label for="planType" class="mb-3">Type de carte</label>
        <mat-radio-group
          class="radio-group flex-column"
          id="planType"
          name="planType"
          (change)="clearPlan()"
          [(ngModel)]="planType">
          <mat-radio-button [value]="'PCS'" checked="true">
            <div class="p-2">
              <b>FRANCE</b>
              <div class="color-grey mt-1">
                Accédez aux <b>Plan Communaux de Sauvegarde</b> de la France métropolitaine et des départements d'outre-mer. Seul le groupe municipalités est disponible, <b>les PCS sont lié à un territoire</b>.
              </div>
            </div>
          </mat-radio-button>
          <mat-radio-button [value]="'PLAN'">
            <div class="p-2">
              <b>INTERNATIONAL</b>
              <div class="color-grey mt-1">
                Accedez aux <b>Plans d'Actions</b> de l'ensemble des usagers du monde. <b>Les plans d'actions sont liés à un utilisateur</b>.
              </div>
            </div>
          </mat-radio-button>
        </mat-radio-group>
      </div>


    <label class="mb-3">Détail de la carte</label>

    <div *ngIf="planType === 'PCS' && this.user?.zones?.length !== 1">
        <app-zone-autocomplete [required]="true" (zone)="setZone($event)"></app-zone-autocomplete>
      </div>

      <div *ngIf="planType === 'PLAN'">
        <app-user-autocomplete [required]="true" (user)="setUser($event)">

        <mat-hint >
          <small *ngIf="isLoading" class="float-end">
            Scan des Plans d'actions de l'usager
          </small>
          <small *ngIf="planError" class="text-danger float-end">
            <b>Attention</b> : Cet utilisateur ne possède pas de plan d'action
          </small>
        </mat-hint>
        </app-user-autocomplete>
      </div>

      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Titre du plan</mat-label>
        <input required matInput [(ngModel)]="plan.title" name="title">
      </mat-form-field>
  </form>
</mat-dialog-content>
<div mat-dialog-actions class="justify-content-center align-items-center border-top p-3">
  <button class="pm-btn pm-btn-primary me-3" [disabled]="!(form.valid && (plan.id_user || plan.code_zone))" [mat-dialog-close]="plan">Ajouter</button>
  <button class="pm-btn" mat-dialog-close cdkFocusInitial>Annuler</button>
</div>
