<header class="main-header d-flex justify-content-between align-items-center">
  <a class="main-header-logo" href="/dashboard" routerLink="dashboard">
    <img src="/assets/images/favicon/android-chrome-192x192.png" [style.height.px]="30" alt="Predict Mapper Logo">
    MAPPER
  </a>

  <div class="me-2">
    <button class="pm-btn" [matMenuTriggerFor]="userMenu" aria-label="User menu">
      <span *ngIf="userName" class="me-2">{{userName}}</span>
      <i class="bi bi-person-circle"></i>
    </button>
    <mat-menu #userMenu="matMenu">
      <button mat-menu-item (click)="logout()">
        <span>Déconnexion</span>
      </button>
    </mat-menu>
  </div>
</header>

<header class="page-header">
  <div class="container">
    <div class="col-12">
      <h1 class="page-header-title">
        <svg class="me-2" width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27.9031 16.9499C28.1497 17.4916 27.9106 18.1302 27.3689 18.3757L15.5232 23.7601C15.3822 23.8247 15.2303 23.857 15.0774 23.857C14.9708 23.857 14.8642 23.8409 14.7608 23.8096L0.761352 19.5021C0.192759 19.3266 -0.125998 18.7235 0.0484563 18.156C0.222911 17.5874 0.827042 17.2654 1.39456 17.4431L15.0042 21.6311L26.4773 16.4158C27.0243 16.1648 27.6586 16.4104 27.9031 16.9499ZM15.0042 17.3236L1.39456 13.1356C0.827042 12.9579 0.223988 13.2799 0.0484563 13.8485C-0.127075 14.416 0.192759 15.019 0.761352 15.1946L14.7608 19.5021C14.8642 19.5333 14.9708 19.5495 15.0774 19.5495C15.2303 19.5495 15.3822 19.5172 15.5232 19.4526L27.3689 14.0682C27.9106 13.8216 28.1497 13.184 27.9031 12.6424C27.6575 12.1029 27.0243 11.8606 26.4773 12.1082L15.0042 17.3236ZM0.00215042 9.9114C-0.0204641 9.45911 0.242295 9.04128 0.659048 8.86467L8.52136 5.5511C10.0688 4.89851 10.8054 4.08439 10.8054 1.21989C10.8054 0.889283 10.9573 0.57591 11.2179 0.37238C11.4785 0.167772 11.8177 0.095621 12.1397 0.174233L26.0735 3.60733C26.5537 3.72579 26.893 4.15762 26.893 4.65298V4.81667C26.894 8.00209 26.8951 10.1052 22.9936 11.7496C20.3821 12.8502 15.5846 15.1149 15.5372 15.1386C15.3929 15.2075 15.2346 15.242 15.0774 15.242C14.9708 15.242 14.8642 15.2258 14.7608 15.1946L0.761352 10.8871C0.328446 10.7535 0.0247649 10.3637 0.00215042 9.9114ZM4.22568 9.69925L14.9945 13.0139C16.192 12.4507 19.9072 10.7137 22.1558 9.76602C24.5367 8.76236 24.7241 7.99024 24.737 5.49726L12.9021 2.57999C12.6943 4.78975 11.8565 6.48261 9.35917 7.5358L4.22568 9.69925Z" fill="white"/>
        </svg>

        Mes cartes existantes
      </h1>
    </div>
  </div>
</header>
<div class="container">
  <div class="row">
    <div class="col-12 mb-5">
      <div class="mt-4 mb-5 d-flex justify-content-between align-items-center">
        <div>
          <mat-form-field class="pm-search me-2" appearance="outline">
            <mat-label>Rechercher</mat-label>
            <input matInput autocomplete="off" [(ngModel)]="term" name="title" (keyup)="searchTerm()">
            <i class="bi bi-search me-3" matSuffix></i>
          </mat-form-field>

          <mat-button-toggle-group
            name="ingredients"
            aria-label="Ingredients"
            [(ngModel)]="typePlanFilter"
            (change)="searchTerm()"
          >
            <mat-button-toggle value="territoire">Territoire</mat-button-toggle>
            <mat-button-toggle value="user">Utilisateur</mat-button-toggle>
            <mat-button-toggle value="Tous">Tous les plans</mat-button-toggle>
          </mat-button-toggle-group>

        </div>
        <button class="pm-btn pm-btn-success" (click)="createPlan()">
          <i class="bi bi-plus"></i> CREER UNE CARTE A0
        </button>
      </div>

      <div class="zone" *ngFor="let zone of listZones">
        <div class="zone-header" (click)="zone.opened = !zone.opened" [class.zone-header-opened]="zone.opened">
          <div>
            <span class="zone-header-name">{{zone.name_zone}}</span>
            <span class="zone-header-insee me-2 ms-2">({{zone.code_zone}})</span>
            <span class="pm-tag">{{zone.list_plan.length}}</span>
          </div>
          <button class="zone-header-toggle" >
            <i class="bi bi-chevron-{{zone.opened ? 'up' : 'down'}}"></i>
          </button>
        </div>
        <div class="zone-content flex-wrap flex gap-3 mt-3 " *ngIf="zone.opened">
          <div *ngFor="let plan of zone.list_plan" class="zone-content-plan">
            <div>
              <div class="zone-content-plan-city">


                <div>
                  {{plan.updated_at | date : 'd/MM/yy hh:mm'}}
                </div>
                <div class="bg-primary text-white rounded rounded-pill px-2 py-1 d-inline-block my-1" [matTooltip]="'Utilisateur : ' + (plan.author_login ?? 'Inconnu')" [matTooltipDisabled]="isAdmin(plan.author)">
                  {{getTypeUser(plan.author)}}
                </div>
                <div class="text-sm">{{plan?.id_user ? 'Plan lié à l\'utilisateur ' + plan?.name_user : 'Plan lié au territoire'}}</div>
              </div>
              <div class="zone-content-plan-title">{{plan.title}} </div>
            </div>
            <div class="mt-3">
              <button routerLink="/plan/{{plan.id}}" class="pm-btn">Editer</button>
            </div>

            <button class="zone-content-plan-actions" [matMenuTriggerFor]="menu">
              <i class="bi bi-three-dots-vertical"></i>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="deletePlan(plan)">
                <i class="bi bi-trash text-danger me-2"></i> Supprimer
              </button>
              <button mat-menu-item (click)="renamePlan(plan)">
                <i class="bi bi-pen me-2"></i> Renommer
              </button>
              <button mat-menu-item (click)="duplicatePlan(plan)">
                <i class="bi bi-file-earmark-plus me-2"></i> Dupliquer
              </button>
            </mat-menu>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
