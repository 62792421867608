import {Component, OnInit, signal} from '@angular/core';
import {MapperService} from '../../services/mapper.service';
import {MatDialog} from '@angular/material/dialog';
import {CreatePlanDialogComponent} from './create-plan.dialog.component';
import {A0} from '../../shared/entities/A0.entity';
import {TitleDialogComponent} from '../plan/title/title.dialog.component';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  private _listZones: any[] = [];
  public listZones: any[] = [];
  public userName = '';
  public term = '';
  public typePlanFilter = '';

  constructor(
    public mapperService: MapperService,
    public authService: AuthService,
    public dialog: MatDialog,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.updateListing();

    this.userName = this.getUserName();
  }

  getUserName(): string {
    return JSON.parse(sessionStorage.getItem('user'))?.user_name ?? '';
  }

  searchTerm(): void {
    if (!!this.term) {
      this.listZones = this._listZones
        .filter(zone => zone.name_zone.toLowerCase().includes(this.term.toLowerCase())
          || zone.code_zone.includes(this.term)
          || zone.users?.some(user => user.toLowerCase().includes(this.term.toLowerCase()))
        );
    } else {
      this.listZones = this._listZones;
    }

    if (this.typePlanFilter) {
      this.listZones = this.filterBy(this.typePlanFilter, this.listZones);
    }

    this.listZones.sort((a, b) => a.name_zone > b.name_zone ? 1 : -1);
  }

  filterBy(type: string, array): any[] {
    switch (type) {
      case 'all':
        return array;
      case 'territoire':
        array = array.map(zone => ({
          ...zone, list_plan: zone.list_plan.filter(plan => !plan.id_user)
        }));
        break;
      case 'user':
        array = array.map(zone => ({
          ...zone, list_plan: zone.list_plan.filter(plan => !!plan.id_user)
        }));
    }

    return array.filter(zone => zone.list_plan.length > 0);
  }

  createPlan(): any {
    this.dialog.open(CreatePlanDialogComponent, {
      width: '600px',
      maxWidth: '100%'
    }).afterClosed().subscribe((plan: A0) => {
      if (plan) {
        this.mapperService.createPlan(plan).subscribe(() => this.updateListing());
      }
    });
  }

  updateListing(): void {
    this.mapperService.getListPlan().subscribe((list: A0[]) => {
      const uniqueCz = Array.from(new Set(list.map(a => a.code_zone)));
      this.listZones = [];
      this._listZones = [];

      uniqueCz.forEach(cz => {
        const listPlan = list.filter(a => a.code_zone === cz);

        this._listZones.push({
          code_zone: cz,
          name_zone: listPlan[0].name_zone,
          users: listPlan.filter(a => !!a.id_user).map(a => a.name_user),
          opened: true,
          list_plan: listPlan
        });
      });

      this.listZones = this._listZones;
      this.listZones.sort((a, b) => a.name_zone > b.name_zone ? 1 : -1);
    });
  }

  deletePlan(a0: A0): void {
    const shouldDelete = confirm('Voulez-vous vraiment supprimer ce plan ?');

    if (shouldDelete) {
      this.mapperService.deletePlan(a0.id).subscribe((res) => {
        this.updateListing();
      });
    }
  }

  renamePlan(a0: A0): void {
    this.dialog.open(TitleDialogComponent, {
      data: {
        plan: a0
      }
    }).afterClosed().subscribe((update) => {
      if (update) {
        this.updateListing();
      }
    });
  }

  duplicatePlan(a0: A0): void {
    this.mapperService.duplicatePlan(a0).subscribe((res) => {
      this.updateListing();
    });
  }

  logout(): void {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/unauthorized']);
    }, () => {
      this.router.navigate(['/unauthorized']);
    });
  }

  getTypeUser(author: string): string {
    if (author.includes('ROOT')) {
      return 'Predict';
    } else if (author.includes('ADMIN')) {
      return 'Predict';
    } else if (author.includes('PARTENAIRE')) {
      return 'Partenaire';
    }

    return 'Client';
  }

  isAdmin(author): boolean {
    return author.includes('ADMIN') || author.includes('ROOT');
  }
}
