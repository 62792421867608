import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {MapperService} from '../../../services/mapper.service';
import {UploadDialogComponent} from '../upload-dialog/upload.dialog.component';
import {A0, PlanAction} from '../../../shared/entities/A0.entity';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-strategie-communale',
  templateUrl: './strategie-communale.component.html',
  styleUrls: ['./strategie-communale.component.scss']
})
export class StrategieCommunaleComponent implements OnInit {

  public idMapA0: string;
  public plan: A0;
  public strategie: any = { strategie_datas: {
      vigilance: [],
      mobilisation: [],
      securisation: [],
      securisation_max: [],
      post_crise: []
    }
  } ;
  public urlIframe: SafeResourceUrl;
  public isDefaultUrlStratCom = false;

  constructor(
    private mapperService: MapperService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.idMapA0 = this.route.snapshot.paramMap.get('id');
    this.getPlan(this.idMapA0);
    this.getStrategie();
    this.isDefaultUrlStratCom = true ;
  }

  getStrategie(): void {
    this.mapperService.getStrategie(this.idMapA0).subscribe(data =>
      {
        this.strategie = data.strategie ;
      }
    );
  }

  updateStrategie(): void {
    console.log('objet json strategies', this.strategie);
    this.mapperService.updateStrategie(this.idMapA0,  this.strategie).subscribe(data =>
    {
      this.mapperService.listPlan = [];
      this.getPlan(this.idMapA0);
    });
  }

  public getPlan(id): void {
    this.mapperService.getPlan(id).subscribe(plan => {
      this.plan = plan;
      const url = this.plan.url_strategiecom;
      this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(url) ;

      this.isDefaultUrl();
    });
  }

  public openModal(): void {
    this.dialog.open(UploadDialogComponent, {
      data: {
        title: '',
        content: `
            <p class="pb-0 mb-0">
               Voulez vous modifier la stratégie communale ?
               <br>
               Le document doit être un pdf de 120mm de haut pour 300mm de large.
            </p>
        `,
        plan: this.plan,
        planAction: PlanAction.STRATEGIE_COM
      },
      panelClass: 'dialog-warning',
      width: '600px'
    }).afterClosed().subscribe((update) => {
      if (update) {
        this.getPlan(this.idMapA0);
      }
    });
  }

  public isDefaultUrl(): void {
    this.mapperService.getDefaultUrlAction(this.idMapA0, PlanAction.STRATEGIE_COM).subscribe(data => {
      this.isDefaultUrlStratCom = data.url === this.plan.url_strategiecom ;
    });
  }
}
