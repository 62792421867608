import {CommonModule} from '@angular/common';
import {NgModule, ModuleWithProviders} from '@angular/core';
import {MapCesiumComponent} from './cesium/map-cesium.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatBadgeModule} from '@angular/material/badge';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatRadioModule} from '@angular/material/radio';
import {GenericDialogComponent} from './dialog/generic.dialog.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {ZoneAutocompleteComponent} from './input/zone-autocomplete.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSliderModule} from '@angular/material/slider';
import {MatIconModule} from '@angular/material/icon';
import {KeysPipe} from './pipe/keys.pipe';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ReplacePipe} from './pipe/replace.pipe';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {CollapseComponent} from './collapse.component';
import {CapitalizePipe} from './pipe/capitalize.pipe';
import {StepsComponent} from './steps/steps.component';
import {BrowserDetectionComponent} from './browser-detection.component';
import {OrderByPipe} from './pipe/order-by.pipe';
import {DynamicPositionComponent} from './dynamic-position.component';
import {MapLayerFilterComponent} from './map-layer-filter.component';
import {UserAutocompleteComponent} from './input/user-autocomplete.component';
import {MatChipsModule} from '@angular/material/chips';

const component = [
  MapCesiumComponent,
  GenericDialogComponent,
  ZoneAutocompleteComponent,
  KeysPipe,
  ReplacePipe,
  CollapseComponent,
  CapitalizePipe,
  StepsComponent,
  MapLayerFilterComponent,
  UserAutocompleteComponent,
  DynamicPositionComponent,
  BrowserDetectionComponent,
  OrderByPipe
];

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule
];

const modulesMaterial = [
  MatSelectModule,
  MatInputModule,
  MatFormFieldModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatSidenavModule,
  MatMenuModule,
  MatProgressBarModule,
  MatChipsModule,
  MatTooltipModule,
  MatRadioModule,
  MatBadgeModule,
  MatButtonToggleModule,
  MatAutocompleteModule,
  MatCheckboxModule,
  MatSliderModule,
  MatIconModule,
  MatSnackBarModule,
  DragDropModule,
  MatSlideToggleModule
];

@NgModule({
    imports: [
        ...modules,
        ...modulesMaterial
    ],
    declarations: [
        ...component,
    ],
    exports: [
        ...component,
        ...modules,
        ...modulesMaterial
    ]
})
export class SharedModule {
  static withProviders(): ModuleWithProviders<any> {
    return  {
      ngModule : SharedModule,
      providers : [
      ]
    };
  }
}
