import {Injectable} from '@angular/core';
import {ModuleInterface} from '../shared/ModuleInterface';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoaderService} from './loader.service';
import {Observable, of} from 'rxjs';
import {A0, PlanAction, Snapshot, SnapshotType, ModuleType, Module} from '../shared/entities/A0.entity';
import {map, tap} from 'rxjs/operators';

@Injectable()
export class MapperService  {

  public moduleMapper: ModuleInterface;
  public listPlan = [];
  public headers: HttpHeaders;

  constructor(
    public httpClient: HttpClient,
    public loaderService: LoaderService,
  ) {
    this.moduleMapper = new ModuleInterface('mapper', this.httpClient, this.loaderService, true);
    this.moduleMapper.addAuthorizationInAllRequest();
  }

  getListPlan(): Observable<A0[]> {
    return this.moduleMapper.get(`maps-A0/list`).pipe(
      tap(list => {
        this.listPlan = list;
      })
    );
  }

  getPlan(id): Observable<A0> {
    return this.moduleMapper.get(`maps-A0/get/${id}`).pipe(map(a => a.map));
  }

  isCustomPdf(id, type: PlanAction, url): Observable<any> {
    return new Observable((observer) => {
      this.moduleMapper.get(`maps-A0/get-default-url/${id}/${type}`).subscribe(res => {
        if (res.url) {
          observer.next(url !== res.url);
        } else {
          observer.error({msg: 'missing url'});
        }
      }, (err) => {
        observer.error(err);
      });
    });
  }

  createPlan(a0: A0): Observable<any> {
    return this.moduleMapper.post(`maps-A0/create`, a0);
  }

  updatePlan(a0: A0): Observable<any> {
    return this.moduleMapper.put(`maps-A0/update/${a0.id}`, a0);
  }

  duplicatePlan(a0: A0): Observable<any> {
    return this.moduleMapper.get(`maps-A0/duplicate/${a0.id}`);
  }

  deletePlan(id): Observable<any> {
    return this.moduleMapper.delete(`maps-A0/delete/${id}`);
  }

  getCartoucheLogos(id): Observable<any> {
    return this.moduleMapper.get(`entete/get-logo/${id}`).pipe(map(a => a.list));
  }

  getSnapshotList(id): Observable<Snapshot[]> {
    return this.moduleMapper.get(`maps-A0/snapshots/get/${id}`).pipe(map(a => a.list));
  }

  getModuleHotList(id): Observable<Module[]> {
    return this.moduleMapper.get(`maps-A0/modules/get/${id}`).pipe(map(a => a.list));
  }
  deleteSnapshot(id, type: SnapshotType): Observable<any> {
    return this.moduleMapper.delete(`maps-A0/snapshots/delete/${id}/${type}`);
  }

  createOrUpdateSnapshot(a0, options: {
    type: SnapshotType,
    snapshot_url_image: string,
    snapshot_url_image_lite?: string,
    position: string,
    pictos?: any[],
    bbox: string,
    title?: string,
    is_custom_upload?: boolean
  }): Observable<any> {
    return this.moduleMapper.post(`maps-A0/snapshots/create-or-update/${a0.id}/${options.type}`, {
      ...options,
      snapshot_url_image: options.snapshot_url_image,
      snapshot_url_image_lite: options.snapshot_url_image_lite || null,
      position: options.position,
      bbox: options.bbox,
      is_custom_upload: options.is_custom_upload || false,
      title: options.title || null
    });
  }

  createOrUpdateModule(a0, options: {
    type: ModuleType,
    position: string
  }): Observable<any> {
    return this.moduleMapper.post(`maps-A0/modules/create-or-update/${a0.id}/${options.type}`, {
      position: options.position
    });
  }

  getStepsDone(id): Observable<any> {
    return this.moduleMapper.get(`maps-A0/steps-dones/get/${id}`);
  }

  setStepDone(id, step: PlanAction): Observable<any> {
    return this.moduleMapper.get(`maps-A0/steps-dones/set-done/${id}/${step}`);
  }

  setStepUndone(id, step: PlanAction): Observable<any> {
    return this.moduleMapper.get(`maps-A0/steps-dones/set-undone/${id}/${step}`);
  }

  uploadSvg(a0, svg): Observable<any> {
    return this.moduleMapper.post(`upload/labels-svg/${a0.id}`, svg);
  }

  uploadSnapshot(a0, file: Blob): Observable<any> {
    return this.moduleMapper.post(`upload/snapshot/${a0.id}`, file);
  }

  buildStrategieAlertCommunalePdf(a0): Observable<any> {
    return this.moduleMapper.get(`strategie-alerte-communale/${a0.id}/build.pdf`);
  }

  updateEchelle(id: string, echelle: object): Observable<any> {
    return this.moduleMapper.put(`echelle/update/` + id, echelle);
  }

  getEchelle( idMapA0: string): Observable<any> {
    return this.moduleMapper.get(`echelle/get/` + idMapA0 );
  }
  getStrategie( idMapA0: string): Observable<any> {
    return this.moduleMapper.get(`strategie-alerte-communale/get/` + idMapA0 );
  }
  updateStrategie(id: string, strategie: object): Observable<any> {
    return this.moduleMapper.put(`strategie-alerte-communale/update/` + id, strategie);
  }

  getlistBuildMapPdf(id: string): Observable<any> {
    return this.moduleMapper.get(`map-A0/build/` + id + `/get-defaults-parameters.json`);
  }

  buildMapPdf(id: string): Observable<any> {
    return this.moduleMapper.get(`map-A0/build/` + id + `.pdf`);
  }

  resetDefaultUrlAction(idMapA0: string, action: PlanAction): Observable<any> {
    return this.moduleMapper.get(`maps-A0/set-default-url/${idMapA0}/${action}`);

  }

  getDefaultUrlAction(idMapA0: string, action: PlanAction): Observable<any> {
    return this.moduleMapper.get(`maps-A0/get-default-url/${idMapA0}/${action}`);

  }

  deleteCartoucheLogo(idLogo): Observable<any> {
    return this.moduleMapper.delete(`entete/delete-logo/${idLogo}`);
  }

  getUserPlanId(id_user: number): Observable<{
    id_user: string,
    uuid_plan: string,
    type_risque: string,
  }> {
    return this.moduleMapper.get(`pas/${id_user}`);
  }

  getGeoJsonPlan(uuid_plan: string): Observable<any> {
    return this.moduleMapper.get(`pas/geojson/${uuid_plan}`);
  }

  getUserWithFilters(limit, offset, filters): Observable<any> {
    return this.moduleMapper.post(`users/admin/search/${limit}/${offset}`, filters )
      .pipe(map((v) => v.list));
  }
}
