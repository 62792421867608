import {Component, ElementRef, Inject, Renderer2, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {A0} from '../../shared/entities/A0.entity';
import {AuthService} from '../../services/auth.service';
import {MapperService} from '../../services/mapper.service';


@Component({
  selector: 'app-create-plan.dialog',
  templateUrl: './create-plan.dialog.component.html',
})
export class CreatePlanDialogComponent {

  plan: A0 = {};
  user;
  planType: 'PCS' | 'PLAN' = 'PCS';
  isLoading = false;

  planError = false;

  constructor(
    public render: Renderer2,
    public el: ElementRef,
    public authService: AuthService,
    public mapperService: MapperService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.authService.getSession().subscribe(user => {
      this.user = user;
      if (this.user.zones.length === 1) {
        this.plan.code_zone = this.user.zones[0].code_zone;
        this.plan.type_zone = this.user.zones[0].type_zone;

        if (this.plan.type_zone !== 'FRA') {
          // Municipalité internationale
          this.plan.id_user = this.user.id_user;
          this.plan.name_user = this.user.user_name;
        }
      }
    });
  }

  setZone(zone): void {
    this.planError = false;

    this.plan.id_user = null;
    this.plan.name_user = null;
    this.plan.code_zone = zone.code_zone;
    this.plan.type_zone = zone.type_zone;
  }

  setUser(user): void {
    this.planError = false;

    this.plan.id_user = user.id_user;
    this.plan.name_user = user.name_user;
    this.plan.type_zone = user.type_zone;
    this.plan.code_zone = user.code_zone;

    this.userHasPlan();
  }

  clearPlan(): void {
    this.plan.id_user = null;
    this.plan.name_user = null;
    this.plan.type_zone = null;
    this.plan.code_zone = null;
    this.planError = false;
  }

  userHasPlan(): void {
    this.isLoading = true;

    this.mapperService.getUserPlanId(this.plan.id_user).subscribe((planId) => {
      this.isLoading = false;
    }, (e) => {
      this.isLoading = false;
      this.planError = true;
    });
  }
}
