<app-browser-detect></app-browser-detect>

<header class="main-header d-flex justify-content-between align-items-center">
  <div>
    <a *ngIf="!isInEdition" href="/plan/{{plan?.id}}" routerLink="/plan/{{plan?.id}}" class="btn">
      <b><i class="bi bi-arrow-left-short"></i></b>
    </a>

    <a *ngIf="isInEdition" href="javascript:void(0)" (click)="isInEdition = false" class="btn">
      <b><i class="bi bi-arrow-left-short"></i></b>
    </a>
  </div>

  <b *ngIf="!isInEdition">
    Gestion des cartes
  </b>

  <b *ngIf="isInEdition">
    <span class="color-grey">Gestion des cartes</span> : Fond de carte
  </b>

  <div class="d-flex justify-content-end me-2">
    <app-steps [type]="'map'"></app-steps>
  </div>
</header>

<div class="page-map">
  <div class="page-map-panel bg-white">
    <div class="pm-panel d-flex justify-content-between flex-column h-100">
      <div class="pm-panel-content">
        <div class="m-3">
          <app-collapse name="Fond de carte" *ngIf="!isInEdition">

            <div  *ngIf="!!plan && currentSnapshot">

              <div class="pm-zoom mt-3 mb-3">
                <span class="pm-zoom-name">Fond de carte
                  <span *ngIf="currentSnapshot.is_custom_upload" > - Personnalisé</span>
                </span>

                <button [disabled]="isLoadingLayer"
                        *ngIf="!currentSnapshot.is_custom_upload"
                        class="pm-btn mt-2 me-2"
                        (click)="isInEdition = true"
                        [matTooltip]="'Éditer'"
                        matTooltipPosition="above">
                  <i class="bi bi-pen"></i>
                </button>

                <button [disabled]="isLoadingLayer" class="pm-btn mt-2 me-2" (click)="delete('MAIN')" [matTooltip]="'Supprimer'" matTooltipPosition="above">
                  <i class="bi bi-trash"></i>
                </button>

                <a [href]="currentSnapshot.snapshot_url_image" target="_blank" download="test" class="d-inline-block pm-btn mt-2 me-2" [matTooltip]="'Télécharger'" matTooltipPosition="above">
                  <i class="bi bi-download"></i>
                </a>
              </div>
            </div>

            <div *ngIf="!!plan && !currentSnapshot">

              <div class="text-center mb-2" >
                <button (click)="isInEdition = true" class="pm-btn mt-3">
                  Generer une vue 2d
                </button>

                <button (click)="inputFileBackground.click()" class="pm-btn mt-3">
                  Uploader un fond de carte personnalisé
                </button>
              </div>
              <i>le ratio recommandé est 1:1.357</i>
            </div>
            <input  style="display: none" #inputFileBackground (change)="onFileSelected($event, 'background')" type="file" id="formFileBackground">
          </app-collapse>

          <app-collapse name="Zooms" *ngIf="!isInEdition">
            <div *ngFor="let zoom of listSnapshot" class="pm-zoom mt-3 mb-3"
                 (mouseenter)="focusZoom(zoom.id_zoom)"
                 (mouseleave)="blurZoom(zoom.id_zoom)"
            >
              <span class="pm-zoom-name">{{zoom.id_zoom | replace : '_' : ' '}}
                <span *ngIf="zoom.is_custom_upload" > - Personnalisé</span>
              </span>
              <button [disabled]="isLoadingLayer" class="pm-btn mt-2 me-2" (click)="setInitialPosition(zoom.id_zoom)" [matTooltip]="'Position initiale'" matTooltipPosition="above">
                <i class="bi bi-bootstrap-reboot"></i>
              </button>

              <button [disabled]="isLoadingLayer" *ngIf="!zoom.is_custom_upload" class="pm-btn mt-2 me-2" routerLink="/plan/{{plan?.id}}/6/{{zoom.id_zoom}}" [matTooltip]="'Éditer'" matTooltipPosition="above">
                <i class="bi bi-pen"></i>
              </button>

              <button [disabled]="isLoadingLayer" class="pm-btn mt-2 me-2" (click)="delete(zoom.id_zoom)" [matTooltip]="'Supprimer'" matTooltipPosition="above">
                <i class="bi bi-trash"></i>
              </button>

              <a [href]="zoom.snapshot_url_image" target="_blank" download="test" class="d-inline-block pm-btn mt-2 me-2" [matTooltip]="'Télécharger'" matTooltipPosition="above">
                <i class="bi bi-download"></i>
              </a>
            </div>

            <div *ngIf="!!plan && listSnapshot.length < 2">
              <div class="text-center">
                <button [disabled]="isLoadingLayer" class="pm-btn mt-3" (click)="addZoom()">
                  Generer une vue 3d
                </button>
                <button [disabled]="isLoadingLayer" class="pm-btn mt-3" (click)="inputFileZoom.click()">
                  Uploader un zoom personnalisé
                </button>
              </div>
              <div class="m-2 mb-0">
                <i>le ratio recommandé est 1:1</i>

              </div>
              <input  style="display: none" #inputFileZoom (change)="onFileSelected($event, 'zoom')" type="file" id="formFileZoom">
            </div>
          </app-collapse>

          <app-collapse name="Étiquettes" *ngIf="!isInEdition">
            <div class="text-center">
              <button [disabled]="isLoadingLayer" class="pm-btn mt-2 me-2" routerLink="/plan/{{plan?.id}}/label">
                Placer les étiquettes
              </button>
            </div>
          </app-collapse>

          <app-collapse name="Titres" [open]="true" >
            <div *ngFor="let title of freeTitles; let index = index " class="pm-titles mt-3 mb-3">
              <span class="pm-titles-name">Titre {{title.id}}</span>
              <button class="pm-btn mt-2 me-2 bt-delete" (click)="deleteTitle(title.id)" [matTooltip]="'Supprimer'" matTooltipPosition="above">
                <i class="bi bi-trash"></i>
              </button>
              <mat-form-field appearance="outline" class="w-100 mt-3 block">
                <mat-label>Titre</mat-label>
                <input matInput type="text" [value]="title.name" (keyup)="updateTitle($event.target, title)" required name="name">
              </mat-form-field>

              <div class="mat-form-field-flex">
                <mat-form-field appearance="outline" style="width:50%">
                  <mat-label>Police</mat-label>
                  <input matInput type="number" [value]="title.fontSize" (change)="updateTitle($event.target, title)" required name="fontSize">
                </mat-form-field>
                <mat-checkbox [checked]="title.shadow" (change)="updateTitle($event, title)">Ombre</mat-checkbox>

              </div>
            </div>
            <div class="text-center">
              <button (click)="addTitle()" class="pm-btn mt-2 me-2">
                Ajouter un nouveau titre
              </button>
            </div>
          </app-collapse>

          <app-collapse name="Calques" *ngIf="isInEdition">
            <p>
              Certains calques sont trop lourds pour être chargés en temps réel
              ( Routes, Surfaces en eau, Cours d'eau, Toponymes )
            </p>

            <div class="text-center">
              <div>
                <mat-form-field appearance="outline" style="width: 100%">
                  <mat-label>Calques</mat-label>
                  <mat-select [(ngModel)]="layersToLoad" multiple>
                    <mat-option *ngFor="let layer of allLayersToLoad" [value]="layer.alias">{{layer.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <button class="pm-btn me-2" [disabled]="isLoadingLayer" (click)="loadAllLayer()">
                <i class="bi bi-stack"></i> Charger les calques
              </button>
            </div>


            <hr>

            <div *ngFor="let item of manageableLayers" class="pm-layer mb-2">

              <div class="d-flex justify-content-between align-items-center">
                <b>{{item.name}}</b>
                <div>
                  <mat-slide-toggle (change)="handleVisibility(item)"
                                    #layerToggle
                                    [name]="item.alias"
                                    [disabled]="!item.layer.entities?.values?.length && !item.layer.imageryProvider"
                                    [checked]="(!!item.layer.entities?.values?.length || !!item.layer.imageryProvider) && item.layer.show">
                  </mat-slide-toggle>
                </div>
              </div>
              <div *ngIf="(item.name === 'ZMI' || item.name === 'ZMS') && item.layer.show && !!item.layer.entities?.values?.length" class="mt-2">
                  <mat-checkbox (change)="handleNiveauxVisibility(item)"
                      #layerToggle
                      [name]="item.alias+'_niveau'"
                      [disabled]="!item.layer.entities?.values?.length"
                      [checked]="item.layer.showNiveau && !!item.layer.entities?.values?.length">
                    Afficher les données avancées
                  </mat-checkbox>
              </div>

              <div *ngIf="!!item.layer.toggleLabels && item.layer?.entities?.values.length" class="mt-1">
                <div class="mb-1" >
                  <mat-checkbox (change)="item.layer.toggleLabels()" [(ngModel)]="item.layer.showLabels">Affiche les labels</mat-checkbox>
                </div>

                <mat-form-field *ngIf="item.layer.showLabels" appearance="outline" class="w-100 mt-3">
                  <mat-label>Distance entre les labels</mat-label>
                  <input matInput type="number" [value]="item.layer.distanceBetweenLabels" (change)="item.layer.updateDistanceBetweenWatercourseLabels($event.target?.value)" required name="label_distance">
                </mat-form-field>
              </div>

              <div *ngIf="item.name === 'PCS'" class="mt-1">
                <mat-form-field appearance="outline" class="w-100 mt-3">
                  <mat-label>Taille des pictos</mat-label>
                  <input matInput type="number" [value]="item.layer.initialSize" (change)="updatePCSpictoSize($event.target, item)" required name="size">
                </mat-form-field>
                <div class="mt-2 cursor-pointer" (click)="item.collapsed = !item.collapsed">
                  Filtrage avancé <i class="bi bi-caret-{{item.collapsed ? 'up' : 'down' }}"></i>
                </div>
                <div class="mt-3" *ngIf="item.collapsed">

                  <map-layer-filter [mapping]="mapComponent.planEntities" [reload]="mapComponent.reloadFilter">

                  </map-layer-filter>
                </div>
              </div>

              <i class="mt-1" *ngIf="!item.layer.autoLoad">
                <small>Ce calque doit être chargé manuellement</small>
              </i>
            </div>
          </app-collapse>
        </div>
      </div>
      <div class="pm-panel-actions text-center p-3" *ngIf="isInEdition">
        <button class="pm-btn pm-btn-primary" [disabled]="isLoadingLayer || isBuildingScreenShot" (click)="print2d()">
          Générer le fond de carte
        </button>
      </div>
    </div>
  </div>
  <div class="page-map-content">
    <app-map-cesium #map *ngIf="!!plan"
                    [snapshot]="currentSnapshot"
                    [plan]="plan"
                    [type]="'MAIN'"
                    [canvasMode]="'2d'"
                    (whenMapIsInit)="mapIsInit()"
                    (isLoadingLayer)="isLoadingLayer = $event"
                    (isBuildingScreenShot)="isBuildingScreenShot = $event"
                    (screenshotEvent)="screenshotEvent($event)">

      <div class="reserved-area-custom-svg" *ngIf="plan?.url_labels_svg && !isInEdition">
        <img class="img-fluid" style="position: absolute;width: 100%;height: 100%;" src="{{plan?.url_labels_svg}}?d={{timestamp}}" *ngIf="plan?.url_labels_svg" alt="Étiquettes">
      </div>
      <div class="reserved-area-custom-background" *ngIf="currentSnapshot?.snapshot_url_image && !isInEdition">
        <img class="img-fluid" src="{{currentSnapshot.snapshot_url_image_lite || currentSnapshot.snapshot_url_image}}?d={{timestamp}}" alt="Fond de carte">
      </div>
      <div class="reserved-area-custom-background" style="background-color: #c8c8c8"
           *ngIf="!isInEdition && !currentSnapshot">
        <button (click)="isInEdition = true" class="pm-btn">
          Generer une vue 2d
        </button>
      </div>
      <div id="MODULE_LEGENDE_STATIC" #moduleLegendeStatic *ngIf="reservedItemModule.MODULE_LEGENDE_STATIC.active" cdkDragBoundary=".dragBoundary" (cdkDragEnded)="dragEndModule($event)" cdkDrag [cdkDragFreeDragPosition]="reservedItemModule.MODULE_LEGENDE_STATIC.convertedPosition" class="reserved-area reserved-area-1">
        <div class="p-2">
          <div class="plan-preview-tag">7</div>
          <div class="plan-preview-name">Légende</div>
        </div>
      </div>
      <div id="MODULE_STRATEGIE_ALERTE_COMMUNALE" #moduleStrategieAlerteCommunale *ngIf="reservedItemModule.MODULE_STRATEGIE_ALERTE_COMMUNALE.active" cdkDragBoundary=".dragBoundary"  (cdkDragEnded)="dragEndModule($event)" cdkDrag [cdkDragFreeDragPosition]="reservedItemModule.MODULE_STRATEGIE_ALERTE_COMMUNALE.convertedPosition" class="reserved-area reserved-area-2">
        <div class="p-2">
          <div class="plan-preview-tag">8</div>
          <div class="plan-preview-name">Alerte communale</div>
        </div>
      </div>
      <div #zoomArea1 id="ZOOM_1" *ngIf="reservedItem.ZOOM_1.active" cdkDragBoundary=".dragBoundary" (cdkDragEnded)="dragEnd($event)" cdkDrag [cdkDragFreeDragPosition]="reservedItem.ZOOM_1.convertedPosition" class="zoom-area zoom-area-1">
        <img class="img-fluid" *ngIf="!!reservedItem.ZOOM_1.snapshot_url_image" src="{{reservedItem.ZOOM_1.snapshot_url_image}}?d={{timestamp}}" alt="{{reservedItem.ZOOM_1.id_zoom}}">
      </div>
      <div #zoomArea2 id="ZOOM_2" *ngIf="reservedItem.ZOOM_2.active" cdkDragBoundary=".dragBoundary" (cdkDragEnded)="dragEnd($event)" cdkDrag [cdkDragFreeDragPosition]="reservedItem.ZOOM_2.convertedPosition" class="zoom-area zoom-area-2">
        <img class="img-fluid" *ngIf="!!reservedItem.ZOOM_2.snapshot_url_image" src="{{reservedItem.ZOOM_2.snapshot_url_image}}?d={{timestamp}}" alt="{{reservedItem.ZOOM_2.id_zoom}}">
      </div>

      <div *ngFor="let title of freeTitles" cdkDragBoundary=".dragBoundary" cdkDrag (cdkDragEnded)="dragEndTitles($event)"
           [style.color] = "title.color"
           [id]="'freeTitle_'+title.id"
           [cdkDragFreeDragPosition]="title.convertedPosition" class="title-area title-area-1"
           [style.fontSize]="title.fontSize +'pt'"
           [style.text-shadow]="title.shadow ? '1px 1px 1px #000000' : ''"
           >
                   {{title.name}}
      </div>



    </app-map-cesium>
  </div>
</div>
