import ImageryProvider from 'cesium/Source/Scene/ImageryProvider';

/**
 * surchage de la configuration de la carte par type_zone (code pays)
 */
export const CUSTOM_MAP_CONF: {
  [key: string]: { // type zone
    background: ImageryProvider; // Fond de carte
    layers_out: string[]; // Liste des layers(par alias) à ne pas afficher
  }
} = {
  CIV: {
    background: new Cesium.WebMapTileServiceImageryProvider({
      url : 'https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile',
      style : 'normal',
      format : 'image/jpeg',
    }),
    layers_out: [
      'zmi',
      'zms',
      'road',
      'railway',
      'hydroSurface',
      'watercourse',
      'places'
    ]
  },
  default: {
    background: null, // Fond de carte Cesium
    layers_out: ['ziparu']
  }
};

export const manageLayerByZone = (viewier, layers, type_zone) => {
  let customConf = CUSTOM_MAP_CONF[type_zone];

  if (!customConf) {
    customConf = CUSTOM_MAP_CONF.default;
  }

  customConf.layers_out.forEach((layerOutAlias) => {
    const layerIdx = layers.findIndex((layer) => layer.alias === layerOutAlias);

    if (layerIdx === -1) {
      return;
    }

    layers[layerIdx].layer.show = false;

    if (layers[layerIdx].layer.entities) {
      viewier.dataSources.remove(layers[layerIdx].layer);
    }

    if (layers[layerIdx].layer.imageryLayers) {
      viewier.imageryLayers.remove(layers[layerIdx].layer);
    }
  });

  return layers.filter((layer) => !customConf.layers_out.includes(layer.alias));
};
