import * as Cesium from 'cesium';

export function mmToPx(mm): number {
  return mm * 3.7795275591;
}

export function capitalize(str: string): string {
  return str[0].toUpperCase() + str.substring(1);
}


export function stringPositionToObject(stringPosition: string): { x: any, y: any } {
  const coords = stringPosition.split(',');

  return {
    x: Number(coords[0]),
    y: Number(coords[1])
  };
}

export function objectPositionToString(objectPosition: { x: any, y: any }): string  {
  return `${objectPosition.x},${objectPosition.y}`;
}

export function convertDimensionWithRatio(from: number[], to: number[], stringPosition: string): any {
  if (!stringPosition) {
    return
  }

  const position = stringPositionToObject(stringPosition);

  const [fromW, fromH] = from;
  const [toW, toH] = to;

  const wRatio = fromW / toW;
  const hRatio = fromH / toH;

  const convertedPos = {
    x: position.x / wRatio,
    y: position.y / hRatio
  };

  return convertedPos
}

export const sortPriority = (priority) => {
  switch (priority) {
    case 'Elevée':
      return 1;
    case 'Moyenne':
      return 2;
    case 'Faible':
      return 3;
    default:
      return 4;
  }
}

export function toDegrees(cartesian3Pos) {
  let pos = Cesium.Cartographic.fromCartesian(cartesian3Pos)
  return [pos.longitude / Math.PI * 180, pos.latitude / Math.PI * 180]
}


export function pointInsideBbox(points, bbox): boolean {
  const [minX, minY, maxX, maxY] = bbox;
  const [x, y] = points;

  return x >= minX && x <= maxX && y >= minY && y <= maxY;
}


export function isPointOn3dScreen(viewer: Cesium.Viewer, point: Cesium.Entity) {
  const screenPosition = Cesium.SceneTransforms.wgs84ToWindowCoordinates(
    viewer.scene, point.position.getValue(viewer.clock.currentTime)
  );

  if (!screenPosition) {
    return false;
  }

  const canvas = viewer.scene.canvas;
  return (
    screenPosition.x >= 0 &&
    screenPosition.x <= canvas.width &&
    screenPosition.y >= 0 &&
    screenPosition.y <= canvas.height
  );
}

export function getVisiblePoints(viewer: Cesium.Viewer, entities: any): any[] {

  const points = [];

  Array.from(entities.values()).forEach((entity: any) => {
    if (!entity.points) {
      return;
    }

    points.push(...entity.points);
  });

  // Filtrer les points visibles
  return Array.from(new Set(points.filter((p) => isPointOn3dScreen(viewer, p)).map((point) => {
    return Number(point.properties.getValue()?.num);
  })));
}

