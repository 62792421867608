
export interface A0 {
  id?: string;
  title?: string;
  name_zone?: string;
  type_zone?: string;
  code_zone?: string ;
  created_at?: string;
  updated_at?: string;
  pdf_url?: string;
  pdf_generationdate?: string;
  url_labels_svg?: string;
  url_ccm?: string;
  url_entete?: string;
  url_legend_dynamic?: string;
  url_legend_static?: string;
  url_echelle?: string;
  url_strategiecom?: string;
  id_user?: number;
  name_user?: string;
}

export enum SnapshotType {
  MAIN = 'MAIN',
  ZOOM_1 = 'ZOOM_1',
  ZOOM_2 = 'ZOOM_2'
}

export enum ModuleType {
  MODULE_LEGENDE_STATIC = 'MODULE_LEGENDE_STATIC',
  MODULE_STRATEGIE_ALERTE_COMMUNALE = 'MODULE_STRATEGIE_ALERTE_COMMUNALE',
  MODULE_ALL_TITLES = 'MODULE_ALL_TITLES'
}

export enum PlanAction {
  CCM = 'ccm',
  ENTETE = 'entete',
  ENJEUX = 'legend_dynamic',
  LEGEND_STATIC = 'legend_static',
  ECHELLE = 'echelle',
  STRATEGIE_COM = 'strategiecom',
  MAP = 'map'
}

export interface Snapshot {
  id_zoom: string;
  mapA0: string;
  snapshot_url_image: string;
  snapshot_url_image_lite?: string;
  snapshot_date: string;
  position: string;
}

export interface Module {
  id_module: string;
  mapA0: string;
  position: string;
}
